import * as utils from './LunarSolarConverter.io.js'

var today = new Date();
var dd = parseInt(String(today.getDate()).padStart(2, '0'));
var mm = parseInt(String(today.getMonth()).padStart(2, '0'));
var yyyy = today.getFullYear();

export var todays_solar_date = new Date(yyyy,mm,dd).toLocaleDateString("en-US");


var solar = new utils.Solar();

solar.solarYear = yyyy
//adding one because apparently event.$M is 0 based months..
solar.solarMonth = mm + 1
solar.solarDay = dd
// console.log(solar.solarYear,solar.solarMonth,solar.solarDay)
var converter = new utils.LunarSolarConverter();

var lunar = converter.SolarToLunar(solar);
export var todays_lunar_date = new Date(lunar.lunarYear,lunar.lunarMonth-1,lunar.lunarDay).toLocaleDateString("en-US");

