import React, { useState } from 'react';
import './RandomPairs.css';

const RandomPairs = () => {
  const [textInput, setTextInput] = useState('');
  const [pairs, setPairs] = useState([]);

  const handleSubmit = (event) => {
    event.preventDefault();
    // Generate pairs here
    const generatedPairs = generatePairs(textInput);
    setPairs(generatedPairs);
    // setTextInput(''); // Reset the input field
  };

  const handleChange = (event) => {
    setTextInput(event.target.value);
  };

  const generatePairs = (text) => {
    const names = text.split('\n').map((name) => name.trim());
    const res = [];

    while (names.length > 3) {
      const first = Math.floor(Math.random() * names.length);
      // Swap with last
      [names[first], names[names.length - 1]] = [names[names.length - 1], names[first]];
      const firstName = names.pop();
      const second = Math.floor(Math.random() * names.length);
      [names[second], names[names.length - 1]] = [names[names.length - 1], names[second]];
      const secondName = names.pop();
      res.push([firstName, secondName]);
    }

    // Add the remaining three
    res.push(names);
    return res;
  };

  return (
    <div className="random-pairs-container">
      <h2>Get a Random Match</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="textInput">Enter names (one per line):</label>
          <textarea
            id="textInput"
            value={textInput}
            onChange={handleChange}
            placeholder={`e.g., John
Prateek
Guillermo
Celine
Ron
Akshat`}
            required
          />
        </div>
        <button type="submit">Generate Pairs</button>
      </form>

      {pairs.length > 0 && (
        <div className="output-form">
          <h3>Generated Pairs:</h3>
          <ul>
            {pairs.map((pair, index) => (
              <li key={index}>{pair.join(' and ')}</li>
            ))}
          </ul>
        </div>
      )}

      <div className="python-code">
        <h3>algorithm (python):</h3>
        <pre>
          <code>
            {`def get_random_pairs(self, names: list[str]) -> str:
  res = []
  while len(names) > 3:
    first = random.randint(0,len(names)-1)
    names[first],names[-1] = names[-1],names[first]
    first_name = names.pop()
    second = random.randint(0,len(names)-1)
    names[second],names[-1] = names[-1],names[second]
    second_name = names.pop()
    res.append([first_name,second_name])

  # add the remaining three
  res = res + [names]
  return res`}
          </code>
        </pre>
      </div>
    </div>
  );
};

export default RandomPairs;
