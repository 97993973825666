import React from 'react';
import './AuthButton.css'

function AuthButton({ isLoggedIn, onLogin, onLogout }) {
  if (isLoggedIn) {
    // User is logged in, display the "Logout" button
    return (
      <button className="login-with-google-btn" onClick={onLogout}>Logout</button>
    );
  } else {
    // User is not logged in, display the "Login" button
    return (
      <button className="login-with-google-btn" onClick={onLogin}>Login to schedule a reminder</button>
    );
  }
}


export default AuthButton;