// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getAuth,GoogleAuthProvider,signInWithPopup} from 'firebase/auth'
import {getFirestore} from '@firebase/firestore'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAlVrmjbL7SNxAm0J4ktASFT_d5jAzyStI",
  authDomain: "calendar-converter-1b274.firebaseapp.com",
  projectId: "calendar-converter-1b274",
  storageBucket: "calendar-converter-1b274.appspot.com",
  messagingSenderId: "556445462710",
  appId: "1:556445462710:web:54d520f799820a0d647617",
  measurementId: "G-V1DF9Z7THJ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app)

const provider = new GoogleAuthProvider()

export const signInWithGoogle = () => {
    return new Promise((resolve, reject) => {
        signInWithPopup(auth, provider)
          .then((result) => {
            const user = result.user;
            localStorage.setItem("user", JSON.stringify(user)); // Store the user as a JSON string
            resolve(user); // Resolve the promise with the user object
            // console.log(user)
          })
          .catch((error) => {
            console.error("Google sign-in error:", error);
            reject(error); // Reject the promise with the error
          });
      });
}

export const db = getFirestore(app)

const analytics = getAnalytics(app);