import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import * as utils from './functions/LunarSolarConverter.io.js';
import { todays_solar_date, todays_lunar_date } from './functions/InitialDate';
import './App.css';
import './Calendar.css';
import { signInWithGoogle } from './components/Firebase';
import AuthButton from './components/AuthBotton.js';
import UserAlertsForm from './components/UserAlertsForm.js';


const converter = new utils.LunarSolarConverter();
const solar = new utils.Solar();
const lunar = new utils.Lunar();

function App() {
  // Set initial state to today's dates.
  const [lunarDate, setLunarDate] = useState(todays_lunar_date);
  const [solarDate, setSolarDate] = useState(todays_solar_date);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState();

  const handleLogin = () => {
    // Update the isLoggedIn state to true (you can customize this logic as needed)
    signInWithGoogle()
      .then((loggedInUser) => {
        // Handle user data or any additional logic here
        console.log(loggedInUser)
        setUser(loggedInUser)
        setIsLoggedIn(true); // Update the isLoggedIn state upon successful login
      })
      .catch((error) => {
        // Handle login error
        console.error("Login error:", error);
      });
  };

  const handleLogout = () => {
    // Update the isLoggedIn state to false (you can customize this logic as needed)
    setUser();
    setIsLoggedIn(false);
  };


  const lunarToSolar = (event) => {
    const initial_lunar_date = new Date(event);
    setLunarDate(initial_lunar_date);

    lunar.lunarYear = event.getFullYear();
    lunar.lunarMonth = event.getMonth() + 1;
    lunar.lunarDay = event.getDate();

    const solar = converter.LunarToSolar(lunar);
    const converted_solar_date = new Date(solar.solarYear, solar.solarMonth - 1, solar.solarDay);

    setSolarDate(converted_solar_date);
  };

  const solarToLunar = (event) => {
    const initial_solar_date = new Date(event);
    setSolarDate(initial_solar_date);

    solar.solarYear = event.getFullYear();
    solar.solarMonth = event.getMonth() + 1;
    solar.solarDay = event.getDate();

    const lunar = converter.SolarToLunar(solar);
    const converted_lunar_date = new Date(lunar.lunarYear, lunar.lunarMonth - 1, lunar.lunarDay);

    setLunarDate(converted_lunar_date);
  };

  return (
    <div className="container">
      <h1>When's the Lunar date?</h1>
      <div className="calendars-grid">
        <div className="calendar-wrapper">
          <h2>Solar Calendar</h2>
          <Calendar
            className="solar-calendar"
            onChange={solarToLunar}
            value={solarDate}
            key={`solar-calendar-${solarDate}`} // Use a unique identifier as the key
          />
        </div>
        <div className="calendar-wrapper">
          <h2>Lunar Calendar</h2>
          <Calendar
            className="lunar-calendar"
            onChange={lunarToSolar}
            value={lunarDate}
            key={`lunar-calendar-${lunarDate}`} // Use a unique identifier as the key
          />
        </div>
      </div>
      <div className="conversion-result">
        {/* <p>
          The Solar Date {new Date(solarDate).toLocaleDateString('en-us')} = Lunar Date{' '}
          {new Date(lunarDate).toLocaleDateString('en-us')}
        </p> */}

        {/* For google button css styling https://codepen.io/mupkoo/pen/YgddgB */}
        <div style={{ marginBottom: '20px' }}>
          <AuthButton
            isLoggedIn={isLoggedIn}
            onLogin={handleLogin}
            onLogout={handleLogout}
          />
        </div>

        {isLoggedIn && <UserAlertsForm solarDate={solarDate} lunarDate={lunarDate} user={user} />}

        <p>{localStorage.getItem("result")}</p>
      </div>
    </div>
  );
}

export default App;
