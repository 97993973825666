import React, { useState, useEffect } from 'react';
import { db } from './Firebase';
import { addDoc, collection, getDocs, updateDoc, doc, deleteDoc, where, query } from 'firebase/firestore';

export default function UserAlertsForm(props) {
    const [description, setDescription] = useState('');
    const [userAlerts, setUserAlerts] = useState([]);
    const userAlertsCollectionRef = collection(db, "UserAlertDates");

    const createNewAlert = async () => {
        if (props.user) {
            await addDoc(userAlertsCollectionRef, {
                name: props.user.displayName,
                email: props.user.email,
                description: description,
                uid: props.user.uid,
                date_alert_solar: new Date(props.solarDate).getTime(),
                date_convert_lunar: new Date(props.lunarDate).getTime(),
                date_created: new Date().getTime()
            });

            refreshUserAlerts();
        }
    }

    const updateDescription = async (id, description) => {
        const userAlertDoc = doc(db, "UserAlertDates", id);
        const newFields = { description: "TO BE UPDATED DESC" };
        await updateDoc(userAlertDoc, newFields);

        refreshUserAlerts();
    }

    const deleteAlert = async (id) => {
        const userAlertDoc = doc(db, "UserAlertDates", id);
        await deleteDoc(userAlertDoc);

        refreshUserAlerts();
    }

    const refreshUserAlerts = async () => {
        if (props.user) {
            const alertsQuery = query(userAlertsCollectionRef, where("uid", "==", props.user.uid));
            const data = await getDocs(alertsQuery);
            setUserAlerts(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        }
    };

    const handleInputChange = (e) => {
        setDescription(e.target.value);
    };

    useEffect(() => {
        refreshUserAlerts();
    }, [props.user]);

    return (
        <div className="App">
            <div style={{ padding: '20px', borderRadius: '5px' }}>
                <h2 style={{ fontSize: '24px', fontWeight: 'bold', margin: '0' }}>
                    Date Conversion:
                </h2>
                <p style={{ fontSize: '18px', margin: '10px 0' }}>
                    <span style={{ fontWeight: 'bold' }}>Solar Date:</span> {new Date(props.solarDate).toLocaleDateString('en-US')} &nbsp;&nbsp;&nbsp;
                    <span style={{ fontWeight: 'bold' }}>Lunar Date:</span> {new Date(props.lunarDate).toLocaleDateString('en-US')}
                </p>
            </div>

            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '30px' }}>
                <input
                    type="text"
                    id="descriptionInput"
                    placeholder="Enter description here"
                    value={description}
                    onChange={handleInputChange}
                    style={{
                        flex: 1,
                        marginRight: '10px',
                        fontSize: '16px',
                        height: '20px', // Increased input box height
                        width: '300px',
                        padding: '5px', // Adjust the padding to center the text vertically
                    }}
                />
                <button
                    onClick={createNewAlert}
                    style={{
                        marginRight: '10px',
                        padding: '10px 20px', // Increased padding for a larger button
                        backgroundColor: '#007bff', // Blue background color
                        color: '#fff', // White text color
                        border: 'none',
                        borderRadius: '5px', // Rounded corners
                        cursor: 'pointer',
                        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Drop shadow
                        fontSize: '16px', // Set the same font size as the input
                    }}
                >
                    Create an alert for this date
                </button>
            </div>



            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <div style={{ flex: 1 }}>
                    <p style={{ margin: 0, fontSize: '14px', fontWeight: 'bold' }}>Description</p>
                </div>
                <div style={{ flex: 1 }}>
                    <p style={{ margin: 0, fontSize: '14px', fontWeight: 'bold' }}>Solar Date</p>
                </div>
                <div style={{ flex: 1 }}>
                    <p style={{ margin: 0, fontSize: '14px', fontWeight: 'bold' }}>Lunar Date</p>
                </div>
                <div style={{ flex: 1 }}></div> {/* Add an empty div to align buttons */}
            </div>
            {userAlerts.map((alertDate) => (
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }} key={alertDate.id}>
                    <div style={{ flex: 1, fontSize: '14px', margin: 0 }}>
                        {alertDate.description}
                    </div>
                    <div style={{ flex: 1, fontSize: '14px', margin: 0 }}>
                        {new Date(alertDate.date_alert_solar).toLocaleDateString('en-US')}
                    </div>
                    <div style={{ flex: 1, fontSize: '14px', margin: 0 }}>
                        {new Date(alertDate.date_convert_lunar).toLocaleDateString('en-US')}
                    </div>
                    <div style={{ flex: 1 }}>

                        <button
                            onClick={() => {
                                deleteAlert(alertDate.id);
                            }}
                            style={{
                                padding: '5px 10px',
                                background: '#dc3545',
                                color: '#fff',
                                border: 'none',
                                cursor: 'pointer',
                            }}
                        >
                            Delete
                        </button>
                    </div>
                </div>
            ))}


        </div>
    );
}
